$mainHeaderHeight: 120px;

/*----SIZES WITH BASE 16PX----*/
$size-1: 0.1em;
$size-2: 0.125em;
$size-3: 0.157em;
$size-4: 0.188em;
$size-5: 0.196em;
$size-6: 0.236em;
$size-7: 0.438em;
$size-10: 0.625em;
$size-11: 0.688em;
$size-12: 0.75em;
$size-13: 0.813em;
$size-14: 0.875em;
$size-15: 0.938em;
$size-16: 1em;
$size-17: 1.063em;
$size-18: 1.125em;
$size-19: 1.188em;
$size-20: 1.25em;
$size-21: 1.313em;
$size-22: 1.375em;
$size-23: 1.438em;
$size-24: 1.5em;
$size-25: 1.563em;
$size-26: 1.625em;
$size-27: 1.688em;
$size-28: 1.75em;
$size-29: 1.813em;
$size-30: 1.875em;
$size-31: 1.938em;
$size-32: 2em;
$size-33: 2.063em;
$size-34: 2.125em;
$size-35: 2.188em;
$size-36: 2.25em;
$size-40: 2.5em;
$size-44: 2.75em;
$size-50: 3.125em;
$size-56: 3.5em;
$size-70: 4.375em;
