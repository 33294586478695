.modal-cancel-order {
    max-width: 30% !important;

    .modal-content {
        padding: 20px 20px !important;

        .modal-header {
            .modal-title {
                width: 100% !important;
                display: flex !important;
                justify-content: space-between !important;
                align-items: center !important;

                h5 {
                    margin: 0 !important;
                }

                button {
                    margin: 0 !important;
                    border: none !important;
                    background-color: transparent !important;
                }
            }
        }

        .modal-body {
            border-top: 0px solid #cccccc !important;
            border-bottom: 0px solid #cccccc !important;

            textarea {
                height: 100px !important;
                resize: none !important;
                border-color: #9c9c9c !important;
            }
        }

        .modal-footer {
            justify-content: space-between !important;
        }
    }
}