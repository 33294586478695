@import "_sizes.scss";
@import "_palette.scss";

.dashboard {
    &__date-card {
        &__semaphore {
            width: $size-12;
            height: $size-12;
            transform: translateY($size-1);
        }
    }
}