.custom-file-input:lang(pt_BR) ~ .custom-file-label::after {
  content: "Importar";
}

.app-header:after {
  content: " ";
  width: 100%;
  height: 3px;
  display: block;
  grid-column: 1/4;
  background-image: -webkit-gradient(linear, right top, left top, from(#d1f821), color-stop(50%, #c2e812), to(#a6ca06));
  background-image: linear-gradient(270deg, #d1f821, #c2e812 50%, #a6ca06);
  position: absolute;
  top: 54px;
}

.btn:not(:disabled):not(.disabled) {
  margin: 10px;
}

.header-fixed .app-header {
  padding-right: 10px;
}

.identifier-environmment {
  width: 100% !important;
  height: 35px !important;
  background-color: #429f4e !important;
  color: #ffffff !important;
  font-weight: 600 !important;
  margin: 0 !important;
  display: flex !important;
  align-items: center !important;
  position: fixed !important;
  top: 55px !important;
  z-index: 2 !important;
  align-content: center !important;

  &.top-0 {
    top: 0 !important;
  }
}

.container-identifier {
  width: 100% !important;
  height: 35px !important;
}

.float-loading {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(51, 51, 51, 0.7);
  z-index: 99999;

  .loading {
    position: fixed;
    left: 50%;
    top: 35%;

    span {
      color: #ffffff;
      font-weight: bold;
      font-size: 15px;
    }
  }
}

div.card-body,
div.card-header {
  i {
    padding-right: 5px;
  }
}

.btn.disabled,
.btn:disabled {
  margin: 10px;
  cursor: not-allowed;
}

table > tbody > tr > td > img,
div.rt-table > div.rt-tbody > div > div > div > img {
  height: 35px;
}

.badge {
  font-size: 95%;
}

span[id*="EditButton"],
span[id*="DetailsButton"],
span[id*="DetailButton"],
span[id*="DeleteButton"],
span[id*="InstallmentButton"],
span[id*="SearchButton"],
i[id*="CopyButton"],
i[id*="SearchButton"] {
  cursor: pointer;
}

.text-gray {
  color: #5c68737a !important;
}

.position-check {
  padding-left: 20px;
}

.no-border {
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  div {
    border-color: rgba(0, 0, 0, 0.1);
  }
}

.ReactTable .table-striped .rt-tbody .rt-tr-group:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-thead .rt-tr {
  text-align: left;
  padding: 0.75rem;
  vertical-align: bottom;
  color: #23282c;
  font-weight: 700;
}

.ReactTable .rt-tfoot .rt-tr {
  text-align: left;
  vertical-align: bottom;
  color: #23282c;
  font-weight: 700;
}

.ReactTable .rt-td {
  padding: 0.75rem;
  vertical-align: bottom;
  border-top: 1px solid #c8ced3;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td,
.ReactTable .rt-tfoot .rt-th,
.ReactTable .rt-tfoot .rt-td {
  border-right: 0px;
}

.table-index .rt-thead .rt-th:first-child,
.table-index .rt-thead .rt-td:first-child,
.table-index .rt-thead .rt-th:last-child,
.table-index .rt-thead .rt-td:last-child,
.table-button .rt-thead .rt-th:last-child,
.table-button .rt-thead .rt-td:last-child,
.table-index .rt-tfoot .rt-th:first-child,
.table-index .rt-tfoot .rt-td:first-child,
.table-index .rt-tfoot .rt-th:last-child,
.table-index .rt-tfoot .rt-td:last-child,
.table-button .rt-tfoot .rt-th:last-child,
.table-button .rt-tfoot .rt-td:last-child {
  flex: 50 0 auto !important;
  width: 60px !important;
}

.table-index .rt-tbody .rt-td:first-child,
.table-index .rt-tbody .rt-td:last-child,
.table-button .rt-tbody .rt-td:last-child {
  flex: 50 0 auto !important;
  width: 60px !important;
}

.ReactTable .rt-noData {
  top: 68%;
}

div.rt-table input[type="checkbox"] {
  margin-left: 20px;
}

.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
  width: 100%;
}

div.transfer-confirm > div > div > div {
  float: left;
}

.border-bottom-title > div > div > div {
  float: left;
  border-bottom: 1px double #ddd;
  border-bottom-width: medium;
}

div.transfer-confirm > div .border-top-title-total {
  border-top: 2px solid #ddd;
}

.transfer-confirm-total {
  margin-top: 10px !important;
}

.search-button {
  margin: 0 0 0 0;
}

.import-button {
  margin: 0 0 0 0 !important;
}

.order {
  .title {
    font-weight: 800;
  }

  .sub-title {
    font-size: 11px;
  }

  i.fa-copy {
    margin-top: 5px;
  }

  .itens {
    div {
      float: left;
    }
  }

  .total-value {
    font-size: 18px;
    font-weight: 500;
  }

  .store {
    img {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      float: left;
      margin: 10px 10px 0px 0px;
    }

    .fantasy-name {
      color: #039be5;
      text-decoration: none;
    }

    span {
      font-size: 12px;
    }
  }
}

.card-box {
  label {
    display: block;
  }
}

.circle-border {
  border-radius: 25px;
  padding: 0.1px 6px;
  color: transparent;
}

.badge-border {
  border-radius: 25px;
  padding: 3px 6px;
  color: transparent;
}

.accordion__item {
  background-color: #fafafa;
  margin-bottom: 10px;
}

.accordion__button {
  cursor: pointer;
  padding: 15px 15px 1px 15px;
}

.accordion__button:before {
  display: inline-block;
  content: "";
  height: 15px;
  width: 15px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
  right: 30px;
  margin-top: 7px;
  position: absolute;
}

.accordion__button[aria-expanded="true"]::before,
.accordion__button[aria-selected="true"]::before {
  transform: rotate(45deg);
}

.version {
  position: absolute;
  height: auto !important;
  right: 10px;
  opacity: 0.3;
  margin-top: -25px;
}

.nav-dashboard {
  margin: -24px -30px 0;
  box-shadow: 0 0 8px #cacaca;

  li {
    a {
      height: 70px;
      font-weight: 600;
      color: #5a5a5a !important;
      display: flex;
      align-items: center;

      &.activated {
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-bottom: 2px solid #8fc856;
      }
    }
  }
}

.bg-p2b {
  background-color: #8fc856 !important;
}

#provider-date {
  width: 0px !important;
  min-width: 0px !important;
  height: 0px !important;
  border: none !important;

  div {
    width: 0px !important;
    min-width: 0px !important;
    height: 0px !important;
    padding: 0 !important;
    border: none !important;

    button {
      display: none !important;
    }
  }
}

.date-picker {
  font-size: 16px !important;
  font-weight: 600 !important;
  padding: 10px 7px 7px 20px !important;
  margin: 0 !important;
  background-color: #8fc856 !important;
  border: 1px solid #8fc856 !important;
  color: #ffffff !important;

  &.date-picker-mobile {
    font-size: 13px !important;
    padding: 10px 7px 7px 8px !important;
  }
}

.col-lg-4-5 {
  flex: 0 0 37.5% !important;
  max-width: 37.5% !important;
  padding: 0 15px !important;
}

.chart-list {
  margin-top: auto !important;
  margin-bottom: auto !important;
  padding-inline-start: 20px !important;

  li {
    font-size: 14px !important;
    color: #23282c !important;
    margin-bottom: 15px !important;

    &::marker {
      unicode-bidi: isolate;
      font-variant-numeric: tabular-nums;
      text-transform: none;
      text-indent: 0px !important;
      text-align: start !important;
      text-align-last: start !important;
    }

    &.color-cae5a6::marker {
      color: #cae5a6;
    }

    &.color-8bc63e::marker {
      color: #8bc63e;
    }

    &.color-fc4141::marker {
      color: #fc4141;
    }

    &.color-5c5c5e::marker {
      color: #5c5c5e;
    }

    &.color-3e64c6::marker {
      color: #3e64c6;
    }

    &.color-fcb141::marker {
      color: #fcb141;
    }
  }
}

.container-card-increase {
  height: -webkit-fill-available !important;

  &:nth-child(1) {
    .card {
      .card-body {
        margin-bottom: 40px !important;
      }
    }
  }

  .card {
    .card-body {
      justify-content: space-between !important;
    }
  }
}

.card-dashboard {
  min-height: 100% !important;
  border-radius: 2px !important;
  border: none !important;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25) !important;
  margin-bottom: 0 !important;

  &.graphDoughnut {
    min-height: 295px !important;
  }

  &.multiple-small {
    min-height: 123px !important;
  }

  &.bar,
  &.line {
    min-height: 170px !important;
  }

  .card-header {
    height: 5px !important;
    background-color: #8fc856 !important;
    padding: 0 !important;
    border-radius: 5px !important;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25) !important;
  }

  .card-body {
    padding: 8px 18px !important;
    display: flex !important;
    flex-direction: column !important;

    &.space-between {
      justify-content: space-between !important;

      .row-multiples {
        &.space-between {
          margin-bottom: 30px !important;
        }
      }
    }

    .title {
      font-size: 14px !important;
      font-weight: 600 !important;
      color: #58595b !important;
      margin-bottom: 7px !important;
    }

    .subtitle {
      font-size: 10px !important;
      color: #58595b !important;
      margin-bottom: 11px !important;
    }

    .value {
      font-size: 22px !important;
      font-weight: 600 !important;
      color: #33282c !important;
      margin-bottom: 19px !important;
    }
  }
}

.row-multiples {
  .col-6 {
    font-size: 14px !important;

    .small-font {
      font-size: 12px !important;
    }

    &:nth-child(1) {
      width: 100px !important;
      text-overflow: ellipsis !important;
      white-space: nowrap !important;
      overflow: hidden !important;
      display: block !important;
    }

    &:nth-child(2) {
      display: flex !important;
      justify-content: flex-end !important;
    }
  }
}

#TitleLoading {
  .row {
    span {
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.add-user {
  margin-bottom: 30px;
  padding: 19px 32px 15px !important;
  background-color: #f5f5f5 !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;

  p {
    font-size: 16px !important;
    font-weight: 700 !important;
    margin: 0 !important;
    text-align: center !important;
    color: #535353 !important;
  }

  span {
    font-size: 14px !important;
    font-weight: 700 !important;
    margin: 0 !important;
    color: #535353 !important;
  }

  hr {
    border-color: #ffffff !important;
  }
}

.register-user {
  label {
    font-size: 14px !important;
    font-weight: 600 !important;
    margin: 0 !important;
  }
}

.new-table {
  .rt-th {
    box-shadow: none !important;
  }
}

.btn_p2b_primary {
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 4px 32px !important;
  background-color: #8fc856 !important;
  color: #ffffff !important;
  border-color: #8fc856 !important;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25) !important;
  border-radius: 5px !important;
}

.btn_p2b_secondary {
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 4px 23px !important;
  background-color: #ffffff !important;
  color: #58595b !important;
  border-color: rgba(83, 83, 85, 0.7) !important;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25) !important;
  border-radius: 5px !important;
}

.w-65 {
  width: 65% !important;
}
