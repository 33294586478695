@import "palette.scss";
@import "sizes.scss";

.app.back-logo {
    background-image: url("../assets/imgs/login-background2.png");
    background-repeat: no-repeat;
    background-size: cover;
    color: #666;

    & .title {
        font-size: 1.2em;
        font-weight: 300;
        padding-left: 0.5em;
        padding-bottom: 0;
        display: flex;
    }

    & .sub-title {
        font-size: 1.75em;
        padding-left: 0.3em;
        font-weight: 500;
        display: flex;
    }

    & .text-center {
        text-align: center;
    }

    & hr {
        margin: 25px 20px 0px 20px;
        padding: 25px 20px 0px 20px;
    }

    & input,
    & .input-group {
        height: 60px;
        font-size: 18px;
    }

    & button,
    a.btn {
        padding: 10px 20px 10px 20px;
        font-size: 18px;
    }

    & .border-top {
        border-top: 4px solid #c2e812 !important;
        border-radius: 0.375em 0.375em 0 0;
    }

    & .validation {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 0.6em;
        padding-top: 0.6em;
        font-weight: 300;
        margin-bottom: 2.667em;
        text-align: left;
        list-style-type: none;

        &--item {
            font-size: 0.867em;
            position: relative;
            &.invalid {
                &:before {
                    content: "";
                    height: 0.53em;
                    width: 0.53em;
                    background: $grey-font2;
                    display: inline-block;
                    border-radius: 50%;
                    margin-right: 0.5em;
                }
            }

            &.valid {
                color: $green;
                &:before {
                    content: "";
                    display: inline-block;
                    border: solid $green;
                    border-width: 0 2px 2px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                    width: 0.3em;
                    height: 0.615em;
                    margin-right: 0.5em;
                }
            }
        }
    }

    & .container-logo {
        display: grid;
        grid-template-columns: 12em 1fr;
        align-items: center;

        & .justify-content-left {
            grid-row: 1 / span 2;
            display: flex;
            justify-content: flex-end;

            & .logo {
                height: 100px;
            }
        }
    }
}
