#chatbot {
  background: #63c2de;
  bottom: 30px;
  right: 30px;
  color: #fff;
  text-align: center;
  padding: 15px;
  position: fixed;
  border: 0;
  opacity: .7;
}
